<template>
  <div class="resume">
    <Loading :loading="loading" style="width: 1200px" />

    <template v-if="!loading">
      <div class="user">
        <img
          class="avatar"
          v-if="detail.avatar_img"
          :src="detail.avatar_img"
          alt=""
        />
        <div class="name">{{ L(detail.name) }}</div>
        <div class="left">
          <img src="@/assets/resume/icon-user.png" alt="" />
          <span>{{ L(detail.english_name) }}</span>
        </div>
        <div class="left">
          <img src="@/assets/resume/icon1.png" alt="" />
          <span v-if="detail.sex === '0'">{{ L("男") }}</span>
          <span v-if="detail.sex === '1'">{{ L("女") }}</span>
        </div>
        <div class="left">
          <img src="@/assets/resume/icon-date.png" alt="" />
          <span>{{ L(detail.birthday) }}</span>
        </div>
        <div class="left">
          <img src="@/assets/resume/icon-mobile.png" alt="" />
          <span>{{ L(detail.mobile) }}</span>
        </div>
        <div class="left">
          <img src="@/assets/resume/icon-email.png" alt="" />
          <span>{{ L(detail.email) }}</span>
        </div>
        <div class="left" style="align-items: flex-start">
          <img src="@/assets/resume/icon-location.png" alt="" />
          <span
            >{{ L(detail.province) }}
            {{ L(detail.city) }}
            {{ L(detail.area) }}
            {{ L(detail.address) }}
          </span>
        </div>

        <div class="left" style="margin-top: 120px">
          <img src="@/assets/resume/icon-arrow.png" alt="" />
          <span>{{ L("去往大陆的次数：") }}{{ L(detail.mainland_num) }}</span>
        </div>
        <div class="left">
          <img src="@/assets/resume/icon-arrow.png" alt="" />
          <span>{{ L("如何得知此活动讯息：") }}{{ L(detail.howto) }}</span>
        </div>
        <div class="left">
          <img src="@/assets/resume/icon-arrow.png" alt="" />

          <span v-if="detail.is_will_stay === '1'">{{
            L("愿意前往大陆就学/创业")
          }}</span>
          <span v-if="detail.is_will_stay === '0'">{{
            L("不愿意前往大陆就学/创业")
          }}</span>
        </div>
        <div class="left">
          <img src="@/assets/resume/icon-arrow.png" alt="" />
          <span v-if="detail.is_will_leader === '1'">{{
            L("有意担任本活动干部")
          }}</span>
          <span v-if="detail.is_will_leader === '0'">{{
            L("无意担任本活动干部")
          }}</span>
        </div>

        <div class="software">
          <div class="left">
            <img src="@/assets/resume/icon-setting.png" alt="" />
            <span style="font-size: 14px">{{ L("擅长软件") }}</span>
          </div>
          <div>{{ L(detail.soft_detail) }}</div>
        </div>

        <div class="software" style="margin-top: 40px">
          <div class="left">
            <img src="@/assets/resume/icon-global.png" alt="" />
            <span style="font-size: 14px">{{ L("语言能力") }}</span>
          </div>
          <div>{{ L("外语等级：") }}{{ L(detail.language) }}</div>
        </div>
      </div>

      <div class="info">
        <div class="header">
          <img src="@/assets/resume/icon-user2.png" alt="" />
          <span>{{ L("个人资料") }}</span>
        </div>
        <div class="body">
          <div class="item">
            <div class="label">{{ L("两岸交流活动经验") }}</div>
            <div class="value">
              {{ L(detail.exchange_detail) }}
            </div>
          </div>
          <div class="item">
            <div class="label">{{ L("实习或工作经历") }}</div>
            <div class="value">
              {{ L(detail.exp_detail) }}
            </div>
          </div>
          <div class="item">
            <div class="label">{{ L("参与本次实习动机") }}</div>
            <div class="value">{{ L(detail.motive_detail) }}</div>
          </div>
          <div class="item">
            <div class="label">{{ L("对两岸关系的看法") }}</div>
            <div class="value">{{ L(detail.viewpoint_detail) }}</div>
          </div>
          <div class="item">
            <div class="label">{{ L("备注") }}</div>
            <div class="value">{{ L(detail.remarks) }}</div>
          </div>
        </div>

        <div class="header">
          <img src="@/assets/resume/icon-study.png" alt="" />
          <span>{{ L("在校信息") }}</span>
        </div>
        <div class="body school">
          <div class="school-info">
            <div class="item">
              <div class="label">{{ L("学校") }}</div>
              <div class="value">{{ L(detail.school) }}</div>
            </div>

            <div class="item">
              <div class="label">{{ L("科系/年级") }}</div>
              <div class="value">
                {{ L(detail.dept) }}/
                {{ L(detail.grade) }}
              </div>
            </div>
          </div>

          <div class="item">
            <div class="label">{{ L("个人简历") }}</div>
            <div class="value">
              {{ L(detail.profile_detail) }}
            </div>
          </div>
          <div class="item">
            <div class="label">{{ L("推荐人") }}</div>
            <div class="value">{{ L(detail.referee) }}</div>
          </div>

          <div class="item">
            <div class="label">{{ L("专业证照") }}</div>
            <div class="value school-images">
              <img v-if="detail.major1_img" :src="detail.major1_img" alt="" />
              <img v-if="detail.major2_img" :src="detail.major2_img" alt="" />
              <img v-if="detail.major3_img" :src="detail.major3_img" alt="" />
              <img v-if="detail.major4_img" :src="detail.major4_img" alt="" />
            </div>
          </div>

          <div class="item">
            <div class="label">{{ L("获奖情况") }}</div>
            <div class="value">{{ L(detail.reward_detail) }}</div>
          </div>

          <div class="item">
            <div class="label">{{ L("社会实践") }}</div>
            <div class="value">
              {{ L(detail.social_detail) }}
            </div>
          </div>
        </div>
        <div class="header">
          <img src="@/assets/resume/icon-bank.png" alt="" />
          <span>{{ L("银行信息") }}</span>
        </div>

        <div class="body bank">
          <div class="item">
            <div class="label">{{ L("银行名称") }}</div>
            <div class="value">{{ L(detail.bank_name) }}</div>
          </div>
          <div class="item">
            <div class="label">{{ L("银行账号") }}</div>
            <div class="value">{{ L(detail.bank_account) }}</div>
          </div>
          <div class="item">
            <div class="label">{{ L("银行代码") }}</div>
            <div class="value">{{ L(detail.bank_code) }}</div>
          </div>
          <div class="item bank-image">
            <div class="label">{{ L("银行存折封面") }}</div>
            <div class="value">
              <img v-if="detail.bank_img" :src="detail.bank_img" alt="" />
            </div>
          </div>

          <div class="item">
            <div class="label">{{ L("银行户名") }}</div>
            <div class="value">{{ L(detail.bank_username) }}</div>
          </div>
          <div class="item">
            <div class="label" style="margin-right: 2em">
              {{ L("是否有中国信托的银行账户") }}
            </div>
            <div class="value">
              <span v-if="detail.is_trust_account === '1'">{{ L("是") }}</span>
              <span v-if="detail.is_trust_account === '0'">{{ L("否") }}</span>
            </div>
          </div>
        </div>

        <div class="header">
          <img src="@/assets/resume/icon-user2.png" alt="" />
          <span>{{ L("补充信息") }}</span>
        </div>

        <div class="body extra">
          <div class="item">
            <div class="label">{{ L("邮编区号") }}</div>
            <div class="value">{{ L(detail.zipcode) }}</div>
          </div>
          <div class="item">
            <div class="label">{{ L("微信账号") }}</div>
            <div class="value">{{ L(detail.wechat) }}</div>
          </div>
          <div class="item">
            <div class="label">{{ L("LINE帐号") }}</div>
            <div class="value">{{ L(detail.line) }}</div>
          </div>
          <div class="item">
            <div class="label">{{ L("特殊才艺") }}</div>
            <div class="value">{{ L(detail.special_skill_detail) }}</div>
          </div>
          <div class="item">
            <div class="label">{{ L("脸书账号") }}</div>
            <div class="value">{{ L(detail.facebook) }}</div>
          </div>
          <div class="item">
            <div class="label">{{ L("特殊病史") }}</div>
            <div class="value">{{ L(detail.special_medical_detail) }}</div>
          </div>

          <div class="item">
            <div class="label">{{ L("特殊饮食") }}</div>
            <div class="value">{{ L(detail.special_diet_detail) }}</div>
          </div>
          <div class="item extra-image">
            <div class="label">{{ L("台胞证") }}</div>
            <div class="value">
              <img v-if="detail.permit_img" :src="detail.permit_img" alt="" />
            </div>
          </div>
          <div class="item">
            <div class="label">{{ L("台胞证号") }}</div>
            <div class="value">{{ L(detail.permit_account) }}</div>
          </div>
        </div>

        <div class="header">
          <img src="@/assets/resume/icon-user1.png" alt="" />
          <span>{{ L("紧急联系人") }}</span>
        </div>
        <div class="body contact">
          <div class="item">
            <div class="label">{{ L("联系人姓名") }}</div>
            <div class="value">{{ L(detail.contact_username) }}</div>
          </div>
          <div class="item">
            <div class="label">{{ L("联系人关系") }}</div>
            <div class="value">{{ L(detail.contact_relationship) }}</div>
          </div>
          <div class="item">
            <div class="label">{{ L("联系人行动电话") }}</div>
            <div class="value">{{ L(detail.contact_mobile) }}</div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
// import { fetchResume } from "@/api/me";
import request from "@/api/request";

export default {
  data() {
    return {
      detail: {},
      loading: false,
    };
  },

  mounted() {
    const { uuid } = this.$route.query;
    if (!uuid) {
      this.$message.error("请填写用户UUID");
      return;
    }
    this.loading = true;
    // fetchResume()
    request({
      url: "/Api/InternResume/detail?uuid=" + uuid,
    })
      .then((res) => {
        this.detail = res;
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>

<style lang="less" scoped>
.resume {
  max-width: 1200px;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
}
.user {
  width: 269px;
  padding: 37px 40px;
  box-sizing: border-box;
  background: linear-gradient(180deg, #587ffe 0%, #5d4deb 100%);
  color: #fff;
}
.user .avatar {
  width: 120px;
  display: block;
  margin: 0 auto;
}
.user .name {
  margin-top: 8px;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}
.user .left {
  margin-bottom: 12px;
  font-size: 13px;
}
.user .left > img {
  width: 14px;
  margin-right: 12px;
}
.user .software {
  margin-top: 120px;
  font-size: 13px;
}
.user .software img {
  width: 32px;
  margin-right: 8px;
}

.info {
  flex: 1;
  padding: 30px;
}

.header {
  display: flex;
  font-size: 14px;
  font-weight: bold;
  color: #587ffe;
  margin-bottom: 16px;
}

.header > img {
  height: 27px;
  width: 27px;
  margin-right: 7px;
}
.header > span {
  flex: 1;
  line-height: 20px;
  border-bottom: 2px solid #587ffe;
}

.body {
  padding: 0 8px 24px;
}

.item {
  display: flex;
  justify-content: baseline;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  color: #333333;
  margin-bottom: 10px;
}
.item .label {
  color: #587ffe;
  font-weight: bold;
  min-width: 10em;
}

.school .label {
  min-width: 6em;
}
.school-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.school-images {
  display: flex;
  align-items: center;
}
.school-images > img {
  width: 200px;
  margin-right: 8px;
}

.bank {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.bank .label {
  min-width: 7em;
}
.bank-image {
  grid-row-start: 2;
  grid-row-end: 5;
  grid-column-start: 2;
  grid-column-end: 3;
}
.bank-image img {
  width: 200px;
}

.extra {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.extra .label {
  min-width: 6em;
}
.extra-image {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 4;
  grid-row-end: 6;
}
.extra-image img {
  width: 200px;
}

.contact {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.contact .label {
  min-width: 6em;
}
</style>